<template>
  <div>
    <modal
      name="modal-dialog-pos-detail"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-card>
        <v-card-text class="objective_content">
          <span class="text-h5">{{ modalTitle }}</span>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="text-right">
              <v-btn
                color="blue"
                @click.stop="onClickAddLastVisitComments()"
                v-if="!isActiveAddLastVisitComments && lastVisitCredential"
              >
                Añadir Última visita Comentarios
              </v-btn>
            </v-col>
          </v-row>
          <v-form
            ref="posdetailForm"
            v-model="valid"
            lazy-validation
            v-if="isActiveAddLastVisitComments && lastVisitCredential"
          >
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="formData.messageContents"
                  v-bind:label="$t('Message')"
                  required
                  :rules="requiredRules"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12" sm="12" md="12" class="text-right">
                <v-btn
                  color="blue"
                  @click.stop="onClickSaveLastVisitComments()"
                >
                  {{ $t("save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <ve-table
                id="datatable-pos-detail"
                fixed-header
                max-height="calc(100vh - 130px)"
                :columns="columns"
                :table-data="table_data"
                :border-around="true"
                :border-x="true"
                :border-y="true"
                :scroll-width="scrollWidth"
              />
              <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.stop="hideModal()">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";

export default {
  name: "DialogPOSDetail",
  props: ["filterMainCredential", "questionColumns", "getDataFromApi"],
  components: {},
  data: function() {
    return {
      row: null,
      column: null,
      table_data: [],
      visit_credentials: [],
      kpi_questions: [],
      cdm_questions: [],

      scrollWidth: 1000,
      columns: [],
      loadingTbInstance: null,
      /********** form-validation options **********/
      isActiveAddLastVisitComments: false,
      formData: { messageContents: null },
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"]
    };
  },
  watch: {},
  computed: {
    lastVisitCredential() {
      let ret = null;
      if (this.visit_credentials.length > 0) ret = this.visit_credentials[0];
      return ret;
    },
    showEmpty() {
      let ret = true;
      if (this.table_data.length > 0) ret = false;
      return ret;
    },
    modalTitle() {
      return `POS Detail`;
    }
  },
  methods: {
    show() {
      this.loadingTbInstance = this.$veLoading({
        target: document.querySelector("#datatable-pos-detail"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingTbInstance.show();
    },
    close() {
      this.loadingTbInstance.close();
    },
    async init(row, column) {
      this.isActiveAddLastVisitComments = false;
      this.row = row;
      this.column = column;
      if (column?.field) {
        const { id, worksession } = row;
        const worksessionPosId = id;
        let fetch_url = `dashboard/cdminterno/getposdetail`;
        const form_data = {
          worksessionPosId,
          questionColumns: this.questionColumns,
          filterMainCredential: this.filterMainCredential
        };
        this.show();
        try {
          const {
            table_data,
            visit_credentials,
            kpi_questions,
            cdm_questions
          } = await ApiService.post(fetch_url, form_data);
          this.table_data = table_data;
          this.visit_credentials = visit_credentials;
          this.kpi_questions = kpi_questions;
          this.cdm_questions = cdm_questions;
          this.generate_new_cols();
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },
    generate_new_cols() {
      let questionColumns = [];
      let table_column_item = {
        align: "center",
        width: 150,
        field: `title`,
        key: `title`,
        title: ` `,
        fixed: "left"
      };
      questionColumns.push(table_column_item);
      if (this.visit_credentials.length > 0) {
        this.visit_credentials.map((credentialItem, index) => {
          let previous_index_label = ``;
          if (index == 1) {
            previous_index_label = ` Visit`;
          } else if (index > 1) previous_index_label = ` - ${index - 1}`;
          previous_index_label += ` (${credentialItem.worksession.date})`;
          let table_column_item = {
            align: "center",
            width: 100,
            field: `wpb_${credentialItem.worksessionPosBrandId}`,
            key: `wpb_${credentialItem.worksessionPosBrandId}`,
            title: `Previous ${previous_index_label}`
            // fixed: "left"
          };
          if (index == 0) {
            table_column_item.fixed = "left";
            table_column_item.title = `Last Visit ${previous_index_label}`;
          }
          questionColumns.push(table_column_item);
        });
        if (this.visit_credentials.length > 3) this.scrollWidth = 1500;
      }
      this.columns = questionColumns;
    },
    async onClickSaveLastVisitComments() {
      if (!this.$refs.posdetailForm.validate()) return;
      let formData = null;
      let currentDate = new Date().toISOString().slice(0, 10);
      if (this.lastVisitCredential) {
        const { worksession } = this.lastVisitCredential;
        if (worksession) {
          currentDate = worksession.date;
        }
      }
      formData = {
        rowType: "POS",
        currentDate,
        messageContents: this.formData.messageContents,
        brandId: this.filterMainCredential.brandId,
        posId: this.row.posId
      };
      const resp = await ApiService.post(
        `dashboard/cdminterno/savelastvisitcomments`,
        formData
      );
      if (resp?.isSentMessage) {
        logInfo("Éxito !");
        this.init(this.row, this.column);
        this.isActiveAddLastVisitComments = false;
      }
    },
    async onClickAddLastVisitComments() {
      this.isActiveAddLastVisitComments = true;
    },
    showModal(row, column) {
      this.init(row, column);
      this.$modal.show("modal-dialog-pos-detail");
    },
    hideModal() {
      this.$modal.hide("modal-dialog-pos-detail");
    }
  },

  async updated() {},
  async mounted() {}
};
</script>

<style></style>
