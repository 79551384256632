<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>CDM Interno</v-card-title>

      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.brandId"
                    v-bind:label="$t('brands.brand')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_brands"
                    :loading="isSearching['brands']"
                    :items="filtered_brands"
                    hide-no-data
                    hide-selected
                    filled
                    clearable
                    required
                    :rules="requiredRules"
                    @change="onChangeFilterMainCredentials('surveys')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="startDate_menu"
                    v-model="select_startDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.from"
                        v-bind:label="$t('brands.start_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.from"
                      no-title
                      @input="select_startDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                    ref="endDate_menu"
                    v-model="select_endDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date.to"
                        v-bind:label="$t('brands.end_date')"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        required
                        :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date.to"
                      no-title
                      scrollable
                      @input="select_endDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                      @change="onChangeDates"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idSurveys"
                    v-bind:label="$t('survey')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_surveys"
                    :loading="isSearching['surveys']"
                    :items="filtered_surveys"
                    hide-no-data
                    filled
                    clearable
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.visualization"
                    v-bind:label="`Visualización`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="label"
                    item-value="value"
                    :items="VISUALIZATIONS"
                    hide-no-data
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idSPVs"
                    v-bind:label="`SPV`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="fullname"
                    item-value="id"
                    :search-input.sync="search_spvs"
                    :loading="isSearching['spvs']"
                    :items="filtered_spvs"
                    hide-no-data
                    filled
                    multiple
                    clearable
                    @change="onChangeFilterMainCredentials('spvs')"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idRoutes"
                    v-bind:label="$t('route')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_routes"
                    :loading="isSearching['routes']"
                    :items="filtered_routes"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.idGPVs"
                    v-bind:label="`GPV`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="fullname"
                    item-value="id"
                    :search-input.sync="search_gpvs"
                    :loading="isSearching['gpvs']"
                    :items="filtered_gpvs"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="text-right">
                  <v-btn color="blue darken-1" @click="onClickFilter">
                    {{ $t("go") }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    class="ml-2"
                    @click="onClickAddQuestion"
                  >
                    Añadir {{ $t("Question") }}
                  </v-btn>
                  <v-btn
                    color="warning"
                    class="ml-2"
                    dark
                    @click="downloadExcel(idAddQuestions)"
                  >
                    <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                    Export Excel
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <DialogAddQuestion
          ref="DialogAddQuestion"
          :handlerAddQuestions="handlerAddQuestions"
          :questionColumns="questionColumns"
          @onChangeAddedQuestions="
            idAddQuestions => onChangeAddedQuestions(idAddQuestions)
          "
        />
        <DialogSendMessage
          ref="DialogSendMessage"
          :filterMainCredential="filterMainCredential"
          :getDataFromApi="getDataFromApi"
        />
        <DialogPhotoView
          ref="DialogPhotoView"
          :filterMainCredential="filterMainCredential"
          :getDataFromApi="getDataFromApi"
        />
        <DialogPOSDetail
          ref="DialogPOSDetail"
          :filterMainCredential="filterMainCredential"
          :questionColumns="questionColumns"
          :getDataFromApi="getDataFromApi"
        />

        <v-row
          v-if="
            ['VISIT_FFVV', 'VISIT_POS'].includes(
              filterMainCredential.visualization
            )
          "
        >
          <v-col cols="12" sm="12" md="12" color="text--primary">
            <ve-table
              ref="tableRefSales"
              id="datatable_dashboard_cdminterno"
              fixed-header
              max-height="calc(100vh - 100px)"
              :columns="columns"
              :table-data="tableData"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
              :expand-option="expandOption"
              row-key-field-name="id"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
        <v-row v-else-if="graph_data">
          <v-col cols="12" sm="12" md="12" color="text--primary">
            <LineBarChart chartTitle="Grafico KPI’s" :data="graph_data" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";
import DialogAddQuestion from "./DialogAddQuestion.vue";
import DialogSendMessage from "./DialogSendMessage.vue";
import DialogPhotoView from "./DialogPhotoView.vue";
import DialogPOSDetail from "./DialogPOSDetail.vue";
import LineBarChart from "@/components/charts/LineBarChart";
import { QUESTION_TYPES } from "@/views/surveys/models.js";

const ChildTableComp = {
  name: "ChildTableComp",
  template: `
        <div class="child-table-comp">
            <ve-table
              :scroll-width="1450"
              :columns="columns"
              :table-data="tableData"
              :expand-option="expandOption"
              row-key-field-name="id"
              :show-header="false"
              :cell-style-option="cellStyleOption"
            />
        </div>
    `,
  props: {
    cellStyleOption: Object,
    row: Object,
    columns: Array,
    tableData: Array,
    expandOption: Object
  },
  data() {
    return {};
  }
};

export default {
  name: "Cdminterno",
  components: {
    LineBarChart,
    DialogAddQuestion,
    DialogSendMessage,
    DialogPhotoView,
    DialogPOSDetail
  },
  data: function() {
    return {
      QUESTION_TYPES: QUESTION_TYPES,
      filter_main_credentials_fetch_url:
        "dashboard/cdminterno/getfiltermaincredentials",
      filterlist_fetch_url: "dashboard/cdminterno",
      VISUALIZATIONS: [
        { value: "VISIT_FFVV", label: "Vista FFVV" },
        { value: "VISIT_POS", label: "Vista POS" },
        { value: "GRAPH_KPIS", label: "Grafico KPI’s" }
      ],

      filterable_col_list: [],
      filterCriteria: {
        name: [],
        repeatsValue: {}
      },
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          // if (column?.isHideOrDeleted) return "hideordeleted-column";
          if (row.rowType === "TOTAL") {
            return "table-body-cell-rejected";
          }
          if (row.rowType === "SPV") {
            return "table-body-cell-approved";
          }
          if (row.rowType === "GPV") {
            return "table-body-cell-incidence";
          }
        },
        headerCellClass: ({ column, rowIndex }) => {
          if (column?.isHideOrDeleted) return "hideordeleted-column";
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      expandOption: {
        beforeExpandRowChange: async ({
          beforeExpandedRowKeys,
          row,
          rowIndex
        }) => {
          // if (row["rowKey"] === 1001) {
          //   alert("Event before expand. Return false to interrupt the expand");
          //   return false;
          // }
          return true;
        },
        afterExpandRowChange: ({ afterExpandedRowKeys, row, rowIndex }) => {
          console.log("row - ", afterExpandedRowKeys, row, rowIndex);
        },
        expandable: ({ row, column, rowIndex }) => {
          if (!row?.Children || row?.Children.length === 0) {
            return false;
          }
        },
        // defaultExpandedRowKeys: [1001],
        render: ({ row, column, rowIndex }, h) => {
          return (
            <ChildTableComp
              cellStyleOption={this.cellStyleOption}
              row={row}
              columns={this.columns}
              tableData={row.Children}
              expandOption={this.expandOption}
            />
          );
        }
      },
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 10,
      pageOptions: [10, 20],
      totaldata: 0,
      isLoadingTableData: false,

      graph_data: null,
      columns: [],
      tableData: [],
      questionColumns: [],
      isVisibleSalesColumn: true,
      idAddQuestions: [],
      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],

      filterMainCredential: {
        brandId: null,
        idSurveys: null,
        date: {
          from: null,
          to: null
        },
        visualization: "VISIT_FFVV",
        idSPVs: [],
        idRoutes: [],
        idGPVs: []
      },
      // filterMainCredential: {
      //   brandId: 2,
      //   idSurveys: 2,
      //   date: {
      //     from: "2024-03-01",
      //     to: "2024-03-03"
      //   },
      //   visualization: "VISIT_FFVV",
      //   idSPVs: [],
      //   idRoutes: [],
      //   idGPVs: []
      // },

      select_startDate_menu: false,
      select_endDate_menu: false,
      isSearching: {},
      search_brands: null,
      filtered_brands: [],
      search_spvs: null,
      filtered_spvs: [],
      search_gpvs: null,
      filtered_gpvs: [],
      search_routes: null,
      filtered_routes: [],
      search_surveys: null,
      filtered_surveys: []
    };
  },
  watch: {
    async search_brands(val) {
      this.searchValues("brands", val);
    },
    async search_surveys(val) {
      this.searchValues("surveys", val);
    },
    async search_routes(val) {
      this.searchValues("routes", val);
    },
    async search_spvs(val) {
      this.searchValues("spvs", val);
    },
    async search_gpvs(val) {
      this.searchValues("gpvs", val);
    }
  },
  computed: {
    salesColumn() {
      return {
        field: "totalSales",
        key: "totalSales",
        title: "Ventas",
        width: 150,
        sortBy: "",
        align: "left"
      };
    },
    defaultColumns() {
      return [
        {
          field: "SEND_POS_MESSAGE",
          key: "SEND_POS_MESSAGE",
          title: "Send POS Message",
          width: 200,
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            let isTotalRow = false;
            let POS_MESSAGE_TYPE = "POS";
            if (row?.rowType) POS_MESSAGE_TYPE = row.rowType;
            let buttonTitle = "Send SPV Message";
            let buttonClass = "primary";
            let lastMessageDate = "";
            if (row?.lastMessage) lastMessageDate = row.lastMessage?.mDate;
            switch (POS_MESSAGE_TYPE) {
              case "SPV":
                buttonTitle = "Send SPV Message";
                buttonClass = "primary";
                break;
              case "GPV":
                buttonTitle = "Send GPV Message";
                buttonClass = "green";
                break;
              case "POS":
                buttonTitle = "Send POS Message";
                buttonClass = "red";
                break;

              default:
                buttonTitle = null;
                buttonClass = null;
                isTotalRow = true;
                break;
            }
            return (
              <div>
                {!isTotalRow ? (
                  <div>
                    <v-btn
                      color={buttonClass}
                      on-click={() => this.onClickSendMessage(row)}
                    >
                      {buttonTitle}
                    </v-btn>
                    {lastMessageDate}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          }
        },
        {
          field: "GO_DETAIL",
          key: "GO_DETAIL",
          title: "Ir",
          width: 100,
          align: "center",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            let rowType = "SPV";
            if (row?.rowType) rowType = row.rowType;
            return (
              <div>
                {rowType === "POS" ? (
                  <v-btn
                    color="blue"
                    on-click={() => this.onClickGoDetail(row, column)}
                  >
                    Ir
                  </v-btn>
                ) : (
                  ""
                )}
              </div>
            );
          }
        }
      ];
    },
    VisitaPosColumns() {
      return [
        {
          field: "",
          key: "expand",
          type: "expand",
          title: "",
          width: 50,
          sortBy: false,
          align: "center"
        },
        {
          field: "chain_name",
          key: "chain_name",
          title: "Cadena",
          width: 250,
          sortBy: "",
          align: "left"
        },
        {
          field: "posId",
          key: "posId",
          title: "IDPOS",
          width: 100,
          sortBy: "",
          align: "left"
        },
        {
          field: "idPosBrand",
          key: "idPosBrand",
          title: "IdPOSbrand",
          width: 150,
          sortBy: "",
          align: "left"
        },
        {
          field: "pos_address",
          key: "pos_address",
          title: "POS DIRECCIÓN",
          width: 270,
          sortBy: "",
          align: "left"
        },
        {
          field: "user_name",
          key: "user_name",
          title: "Name",
          width: 200,
          sortBy: "",
          align: "left"
        },
        {
          field: "totalPS",
          key: "totalPS",
          title: "Tienda Perfecta",
          width: 150,
          sortBy: "",
          align: "left"
        }
      ];
    },
    VisitFFVVColumns() {
      return [
        {
          field: "",
          key: "expand",
          type: "expand",
          title: "",
          width: 50,
          sortBy: false,
          align: "center"
        },
        {
          field: "chain_name",
          key: "chain_name",
          title: "Cadena",
          width: 250,
          sortBy: "",
          align: "left"
        },
        {
          field: "posId",
          key: "posId",
          title: "IDPOS",
          width: 100,
          sortBy: "",
          align: "left"
        },
        {
          field: "idPosBrand",
          key: "idPosBrand",
          title: "IdPOSbrand",
          width: 150,
          sortBy: "",
          align: "left"
        },
        {
          field: "pos_address",
          key: "pos_address",
          title: "POS DIRECCIÓN",
          width: 270,
          sortBy: "",
          align: "left"
        },
        {
          field: "user_name",
          key: "user_name",
          title: "POS",
          width: 200,
          sortBy: "",
          align: "left"
        },
        {
          field: "sumVisitCount",
          key: "sumVisitCount",
          title: "Visitas",
          width: 100,
          sortBy: "",
          align: "left"
        },
        {
          field: "totalPS",
          key: "totalPS",
          title: "Tienda Perfecta",
          width: 150,
          sortBy: "",
          align: "left"
        }
      ];
    },
    GraficoKPIColumns() {
      return [
        {
          field: "",
          key: "expand",
          type: "expand",
          title: "",
          width: 50,
          sortBy: false,
          align: "center"
        },
        {
          field: "user_name",
          key: "user_name",
          title: "Name",
          width: 200,
          sortBy: "",
          align: "left"
        },
        {
          field: "totalPS",
          key: "totalPS",
          title: "Tienda Perfecta",
          width: 150,
          sortBy: "",
          align: "left"
        }
      ];
    },
    showEmpty() {
      let ret = true;
      if (this.tableData.length > 0) {
        ret = false;
      }
      return ret;
    }
  },
  methods: {
    onChangeAddedQuestions(idAddQuestions) {
      this.idAddQuestions = idAddQuestions;
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable_dashboard_cdminterno"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filter_main_credentials_fetch_url}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      let form_data = { ...this.filterMainCredential };
      this.isSearching[fieldname] = true;
      const resp = await ApiService.post(fetch_url, form_data);
      if (fieldname === "brands") {
        this.filtered_brands = resp.data;
      } else if (fieldname === "surveys") {
        this.filtered_surveys = resp.data;
      } else if (fieldname === "spvs") {
        this.filtered_spvs = resp.data;
      } else if (fieldname === "gpvs") {
        this.filtered_gpvs = resp.data;
      } else if (fieldname === "routes") {
        this.filtered_routes = resp.data;
      }
      this.isSearching[fieldname] = false;
    },
    onChangeFilterMainCredentials(fieldname) {
      this.searchValues(fieldname, "");
    },
    onChangeDates() {
      if (
        this.filterMainCredential.startDate > this.filterMainCredential.endDate
      ) {
        this.filterMainCredential.endDate = this.filterMainCredential.startDate;
      }
    },
    async onClickFilter() {
      if (this.$refs.form.validate()) {
        this.getDataFromApi();
      }
    },
    async onClickAddQuestion() {
      if (this.$refs.form.validate()) {
        this.$refs.DialogAddQuestion.showModal(
          this.filterMainCredential,
          this.idAddQuestions
        );
      }
    },
    handlerAddQuestions(idAddQuestions) {
      if (idAddQuestions) {
        this.getDataFromApi(idAddQuestions);
      }
    },
    onClickSendMessage(row) {
      let rowType = "POS";
      if (row?.rowType === "SPV" || row?.rowType === "GPV")
        rowType = row?.rowType;
      this.$refs.DialogSendMessage.showModal(row, rowType);
    },
    async onClickGoDetail(row, column) {
      this.$refs.DialogPOSDetail.showModal(row, column);
    },
    async onClickPhotoView(row, column) {
      if (column?.field) {
        const [prefix, surveyQuestionId] = column.field.split("_");
        const { id, worksession } = row;
        const worksessionPosId = id;
        let fetch_url = `dashboard/cdminterno/getphotoview`;
        const form_data = {
          worksessionPosId,
          surveyQuestionId,
          filterMainCredential: this.filterMainCredential
        };
        this.show();
        try {
          const response = await ApiService.post(fetch_url, form_data);
          this.$refs.DialogPhotoView.showModal(
            row,
            column,
            this.questionColumns,
            surveyQuestionId,
            response?.foundResponse
          );
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },
    generate_new_cols(
      visualizationType,
      choosedQuestionColumns,
      kpi_questions
    ) {
      this.columns = [];
      let columns = [...this.defaultColumns];
      if (this.sorting && Object.keys(this.sorting).length > 0) {
        let col_sorting = null;
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== "") {
            col_sorting = {
              key,
              value
            };
          }
        }
        for (let i = 0; i < columns.length; i++) {
          if (col_sorting !== null && col_sorting.key === columns[i].key) {
            columns[i].sortBy = col_sorting.value;
          } else {
            columns[i].sortBy = "";
          }
        }
      }
      let questionColumns = [];
      if (visualizationType === "VISIT_FFVV") {
        if (choosedQuestionColumns.length > 0) {
          for (const questionItem of choosedQuestionColumns) {
            const { id, name, isHideOrDeleted } = questionItem;
            let survey_question_push_item = {
              isHideOrDeleted,
              align: "center",
              width: 120,
              field: `QUESTIONCELL_${id}`,
              key: `QUESTIONCELL_${id}`,
              title: `${name}`
            };
            questionColumns.push(survey_question_push_item);
          }
        }
      } else {
        let countKPIQuestions = kpi_questions.length;
        let cdm_additional_questions = choosedQuestionColumns.slice(
          countKPIQuestions
        );
        for (const questionItem of kpi_questions) {
          const { id, name, isHideOrDeleted } = questionItem;
          let survey_question_push_item = {
            isHideOrDeleted,
            align: "center",
            width: 150,
            field: `QUESTIONCELL_${id}`,
            key: `QUESTIONCELL_${id}`,
            title: `${name}`
          };
          questionColumns.push(survey_question_push_item);
        }
        let last_visit_comments_push_item = {
          align: "center",
          width: 200,
          field: `LAST_VISIT_COMMENTS`,
          key: `LAST_VISIT_COMMENTS`,
          title: `Última visita Comentarios`
        };
        questionColumns.push(last_visit_comments_push_item);
        for (const questionItem of cdm_additional_questions) {
          const { id, name, isHideOrDeleted } = questionItem;
          let survey_question_push_item = {
            isHideOrDeleted,
            align: "center",
            width: 150,
            field: `QUESTIONCELL_${id}`,
            key: `QUESTIONCELL_${id}`,
            title: `${name}`
          };
          survey_question_push_item = this.insertRenderCellEvent(
            survey_question_push_item,
            questionItem
          );
          questionColumns.push(survey_question_push_item);
        }
      }
      if (visualizationType === "VISIT_POS") {
        this.columns = [
          ...this.VisitaPosColumns,
          this.isVisibleSalesColumn && this.salesColumn,
          ...questionColumns,
          ...columns
        ];
      } else if (visualizationType === "VISIT_FFVV") {
        this.columns = [
          ...this.VisitFFVVColumns,
          this.isVisibleSalesColumn && this.salesColumn,
          ...questionColumns,
          ...columns
        ];
      } else {
        this.columns = [
          ...this.GraficoKPIColumns,
          this.isVisibleSalesColumn && this.salesColumn,
          ...questionColumns,
          ...columns
        ];
      }

      // if (this.isVisibleSalesColumn) {
      //   this.columns = [
      //     ...columns.slice(0, 7),
      //     this.salesColumn,
      //     ...questionColumns,
      //     ...columns.slice(7)
      //   ];
      // } else {
      //   this.columns = [
      //     ...columns.slice(0, 7),
      //     ...questionColumns,
      //     ...columns.slice(7)
      //   ];
      // }
    },
    insertRenderCellEvent(survey_question_push_item, questionItem) {
      let ret = { ...survey_question_push_item };
      ret.renderBodyCell = ({ row, column, rowIndex }, h) => {
        let cellValue = row[column.field];
        let questionType = questionItem?.survey_question_type?.type;
        return (
          <div>
            {cellValue}
            {[QUESTION_TYPES.PHOTO].includes(questionType) &&
            row.rowType !== "TOTAL" ? (
              <v-btn
                color="primary"
                on-click={() => this.onClickPhotoView(row, column)}
              >
                Foto View
              </v-btn>
            ) : (
              ""
            )}
          </div>
        );
      };
      return ret;
    },
    async getDataFromApi(idAddQuestions) {
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#datatable_dashboard_cdminterno .filterable-column"
      });
      let fetch_url = "dashboard/cdminterno";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      if (idAddQuestions) {
        form_data = { ...form_data, idAddQuestions };
      }
      this.isVisibleSalesColumn = true;
      try {
        const resp = await ApiService.post(fetch_url, form_data);
        this.questionColumns = resp.questionColumns;
        this.isVisibleSalesColumn = resp.isVisibleSalesColumn;
        this.tableData = resp.data;
        this.totaldata = resp.total_count;
        if (
          ["VISIT_FFVV", "VISIT_POS"].includes(
            this.filterMainCredential.visualization
          )
        ) {
          if (this.filterMainCredential.visualization === "VISIT_FFVV") {
            this.generate_new_cols(
              this.filterMainCredential.visualization,
              this.questionColumns
            );
          } else if (this.filterMainCredential.visualization === "VISIT_POS") {
            let kpi_questions = resp.kpi_questions;
            this.generate_new_cols(
              this.filterMainCredential.visualization,
              this.questionColumns,
              kpi_questions
            );
          }
        } else {
          this.graph_data = resp.graph_data;
        }
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel(idAddQuestions) {
      if (!this.$refs.form.validate()) return;
      this.show();
      const { pageIndex, pageSize, sorting, filterCriteria } = this;
      let query_params_string = this.getQueryParamsGlobal({
        pageIndex,
        pageSize,
        sorting,
        filterCriteria,
        filterableColumnsSelector:
          "#datatable_dashboard_cdminterno .filterable-column"
      });
      let fetch_url = "dashboard/cdminterno/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      let form_data = { ...this.filterMainCredential };
      if (idAddQuestions) {
        form_data = { ...form_data, idAddQuestions };
      }
      try {
        const response = await ApiService.post(fetch_url, form_data, {
          responseType: "blob"
        });
        download(response, "data.xlsx");
        this.close();
      } catch (error) {
        console.log("error - ", error);
        this.close();
      }
    },
    async init() {
      this.columns = [...this.VisitFFVVColumns, ...this.defaultColumns];
      this.searchValues("brands", "");
      this.searchValues("surveys", "");
      this.searchValues("spvs", "");
      this.searchValues("gpvs", "");
      this.searchValues("routes", "");
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>
.ve-table
  .ve-table-container
  .ve-table-content-wrapper
  table.ve-table-content
  tbody.ve-table-body
  .ve-table-expand-tr
  .ve-table-expand-td-content {
  padding: 0;
}
.child-table-comp .ve-table .ve-table-container .ve-table-content-wrapper {
  overflow: hidden;
}
th.ve-table-header-th.hideordeleted-column {
  background: #757575 !important;
}
#datatable_dashboard_cdminterno
  .ve-table-container
  .ve-table-content-wrapper
  table.ve-table-content
  thead.ve-table-header.ve-table-fixed-header
  tr
  th {
  z-index: 7 !important;
}
#datatable_dashboard_cdminterno
  .ve-table-container
  .ve-table-content-wrapper
  table.ve-table-content
  tbody.ve-table-body
  .ve-table-expand-tr
  .ve-table-expand-td-content {
  z-index: 6 !important;
}
</style>
