<template>
  <div>
    <modal
      name="modal-dialog-photo-view"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-form ref="portalForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">{{ modalTitle }}</span>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <ImageSlider :attachments="getAttachments(foundResponse)" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <h6 class="my-2 text-center">GPV</h6>
                <div class="d-flex justify-content-center">
                  <v-rating
                    background-color="pink lighten-3"
                    color="pink"
                    dense
                    size="28"
                    v-model="saveData.scoreGPV"
                  ></v-rating>
                </div>
                <v-textarea
                  :label="$t('comments')"
                  hide-details
                  v-model="saveData.commentsGPV"
                  :required="true"
                  :rules="requiredRules"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <h6 class="my-2 text-center">
                  {{ $t("salesforce.Parent") }}
                </h6>
                <div class="d-flex justify-content-center">
                  <v-rating
                    background-color="pink lighten-3"
                    color="pink"
                    dense
                    size="28"
                    v-model="saveData.scoreBrand"
                  ></v-rating>
                </div>
                <v-textarea
                  :label="$t('comments')"
                  hide-details
                  v-model="saveData.commentsBrand"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <h6 class="my-2 text-center">
                  {{ $t("brands.brand") }}
                </h6>
                <div class="d-flex justify-content-center">
                  <v-rating
                    background-color="pink lighten-3"
                    color="pink"
                    dense
                    size="28"
                    v-model="saveData.scoreAdmin"
                  ></v-rating>
                </div>
                <v-textarea
                  :label="$t('comments')"
                  hide-details
                  v-model="saveData.commentsAdmin"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="onClickSavePortal()">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import ImageSlider from "./ImageSlider.vue";

export default {
  name: "DialogPhotoView",
  props: ["filterMainCredential", "getDataFromApi"],
  components: { ImageSlider },
  data: function() {
    return {
      row: null,
      column: null,
      surveyQuestionId: null,
      questionColumns: [],
      foundResponse: null,
      saveData: {},

      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"]
    };
  },
  watch: {},
  computed: {
    worksessionSurveyPhotosPortal() {
      let ret = {
        isWarning: null,
        scoreGPV: null,
        scoreBrand: null,
        scoreAdmin: null,
        commentsGPV: null,
        commentsBrand: null,
        commentsAdmin: null
      };
      if (
        this.foundResponse &&
        this.foundResponse?.worksession_survey_photos_portal
      ) {
        ret = this.foundResponse?.worksession_survey_photos_portal;
      }
      return ret;
    },
    currentQuestion() {
      let ret = null;
      if (this.surveyQuestionId && this.questionColumns.length > 0) {
        let found = this.questionColumns.find(
          el => el.id == this.surveyQuestionId
        );
        if (found) ret = found;
      }
      return ret;
    },
    modalTitle() {
      let ret = "";
      if (this.currentQuestion) {
        ret = this.currentQuestion.name;
      }
      return ret;
    }
  },
  methods: {
    getAttachments(portalItem) {
      let ret = [];
      if (portalItem) {
        ret = portalItem?.worksession_survey_response_attachments;
      }
      return ret;
    },
    async init(row, column, questionColumns, surveyQuestionId, foundResponse) {
      this.row = row;
      this.column = column;
      this.questionColumns = questionColumns;
      this.surveyQuestionId = surveyQuestionId;
      this.foundResponse = foundResponse;
      this.createSaveData();
    },
    createSaveData() {
      this.saveData = {
        scoreGPV: 0,
        scoreBrand: 0,
        scoreAdmin: 0,
        commentsGPV: null,
        commentsBrand: null,
        commentsAdmin: null,
        worksessionSurveyResponseId: this.foundResponse.id
        // scoreGPVBy: 1 - userId
      };
      if (this.worksessionSurveyPhotosPortal) {
        const {
          commentsGPV,
          scoreGPV,
          commentsBrand,
          scoreBrand,
          commentsAdmin,
          scoreAdmin
        } = this.worksessionSurveyPhotosPortal;
        this.saveData = {
          ...this.saveData,
          commentsGPV,
          scoreGPV,
          commentsBrand,
          scoreBrand,
          commentsAdmin,
          scoreAdmin
        };
      }
    },
    async onClickSavePortal() {
      if (!this.saveData) return false;
      let validation = true;
      if (!this.saveData.commentsGPV || this.saveData.commentsGPV == "") {
        validation = false;
      }
      if (!this.saveData.scoreGPV) {
        validation = false;
      }
      if (!validation) {
        logError("Debes dejar comentarios y puntuaciones para el portal.");
        return;
      }
      const resp = await ApiService.post(`dashboard/cdminterno/saveportal`, {
        ...this.saveData
      });
      if (resp?.upserted) {
        logInfo("Éxito !");
        this.getDataFromApi();
        this.hideModal();
      }
    },
    showModal(row, column, questionColumns, surveyQuestionId, foundResponse) {
      this.init(row, column, questionColumns, surveyQuestionId, foundResponse);
      this.$modal.show("modal-dialog-photo-view");
    },
    hideModal() {
      this.$modal.hide("modal-dialog-photo-view");
    }
  },

  async updated() {},
  async mounted() {}
};
</script>

<style></style>
