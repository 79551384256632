<template>
  <div>
    <modal
      name="modal-dialog-send-message"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-form ref="messageForm" v-model="valid" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">{{ modalTitle }}</span>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="0" md="12">
                <v-textarea
                  v-model="formData.messageContents"
                  v-bind:label="$t('Message')"
                  required
                  :rules="requiredRules"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click.stop="onClickSaveMessage()"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";

export default {
  name: "DialogSendMessage",
  props: ["filterMainCredential", "getDataFromApi"],
  components: {},
  data: function() {
    return {
      row: null,
      rowType: "POS",
      formData: {
        messageContents: ""
      },
      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"]
    };
  },
  watch: {},
  computed: {
    modalTitle() {
      return `Send ${this.rowType} Message`;
    }
  },
  methods: {
    async init(row, rowType) {
      this.row = row;
      this.rowType = rowType;
      this.formData.messageContents = "";
    },
    async onClickSaveMessage() {
      if (!this.$refs.messageForm.validate()) return;
      let formData = null;
      const currentDate = new Date().toISOString().slice(0, 10);
      if (this.rowType === "POS") {
        formData = {
          currentDate,
          rowType: this.rowType,
          messageContents: this.formData.messageContents,
          brandId: this.filterMainCredential.brandId,
          posId: this.row.posId
        };
      } else {
        formData = {
          SPV_GPV_ID: this.row?.id,
          rowType: this.rowType,
          currentDate,
          messageContents: this.formData.messageContents
        };
      }
      const resp = await ApiService.post(
        `dashboard/cdminterno/sendmessage`,
        formData
      );
      if (resp?.isSentMessage) {
        logInfo("Éxito !");
        this.getDataFromApi();
        this.hideModal();
      }
    },
    showModal(row, rowType) {
      this.init(row, rowType);
      this.$modal.show("modal-dialog-send-message");
    },
    hideModal() {
      this.$modal.hide("modal-dialog-send-message");
    }
  },

  async updated() {},
  async mounted() {}
};
</script>

<style></style>
