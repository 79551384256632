<template>
  <div>
    <modal
      name="modal-dialog-add-question"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <span class="text-h5">Añadir {{ $t("Question") }}</span>
          </v-card-text>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  class="d-flex justify-content-center"
                >
                  <v-checkbox
                    dense
                    v-model="isOnlyActiveQuestions"
                    label="Solo preguntas activas"
                    hide-details="auto"
                    @change="onChangeIsOnlyActiveQuestions"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="0" md="3"> </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="idAddQuestions"
                    v-bind:label="$t('Question')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="title"
                    item-value="id"
                    :search-input.sync="search_questions"
                    :loading="isSearching"
                    :items="filtered_questions"
                    hide-no-data
                    filled
                    clearable
                    multiple
                    required
                    :rules="requiredRulesArray"
                    @change="onChangeAddedQuestions"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click.stop="onClickAddQuestion()"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";

export default {
  name: "DialogAddQuestion",
  props: ["handlerAddQuestions", "questionColumns"],
  components: {},
  data: function() {
    return {
      filterMainCredential: {},
      idAddQuestions: [],
      isOnlyActiveQuestions: false,
      isSearching: false,
      search_questions: null,
      filtered_questions: [],
      /********** form-validation options **********/
      valid: true,
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"]
    };
  },
  watch: {
    async search_questions(val) {
      this.searchValues(val);
    }
  },
  computed: {},
  methods: {
    onChangeAddedQuestions() {
      this.$emit("onChangeAddedQuestions", this.idAddQuestions);
    },
    onChangeIsOnlyActiveQuestions() {
      this.searchValues("");
    },
    async searchValues(val) {
      if (!val) val = "";
      let exceptionIdQuestions = this.questionColumns.map(el => el.id);
      if (exceptionIdQuestions) {
        exceptionIdQuestions = exceptionIdQuestions.filter(
          el => !this.idAddQuestions.includes(el)
        );
      }
      let fetch_url = `dashboard/cdminterno/getavailablequestions?filterValue=${val}`;
      let form_data = {
        ...this.filterMainCredential,
        isOnlyActiveQuestions: this.isOnlyActiveQuestions,
        exceptionIdQuestions
      };
      this.isSearching = true;
      const resp = await ApiService.post(fetch_url, form_data);
      this.filtered_questions = resp.data;
      this.isSearching = false;
    },
    onClickAddQuestion() {
      this.handlerAddQuestions(this.idAddQuestions);
      this.hideModal();
    },
    showModal(filterMainCredential, idPrevAddQuestions) {
      this.idAddQuestions = _.uniq([
        ...this.idAddQuestions,
        ...idPrevAddQuestions
      ]);
      this.filterMainCredential = filterMainCredential;
      this.searchValues(null);
      this.$modal.show("modal-dialog-add-question");
    },
    hideModal() {
      this.$modal.hide("modal-dialog-add-question");
    }
  },

  async updated() {},
  async mounted() {}
};
</script>

<style></style>
