<template>
  <div>
    <div class="image-slider" v-if="slides.length > 0">
      <div
        class="slides"
        :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
      >
        <div v-for="(slide, index) in slides" :key="index" class="slide">
          <img :src="slide" alt="Slide" />
        </div>
      </div>
      <button
        class="btn_slider btn_prev"
        @click.prevent="prevSlide"
        v-show="currentIndex > 0"
      >
        <b-icon
          class="mx-1"
          variant="success"
          icon="arrow-left-circle"
          font-scale="3"
        ></b-icon>
      </button>
      <button
        class="btn_slider btn_next"
        @click.prevent="nextSlide"
        v-show="currentIndex < slides.length - 1"
      >
        <b-icon
          class="mx-1"
          variant="success"
          icon="arrow-right-circle"
          font-scale="3"
        ></b-icon>
      </button>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
export default {
  name: "ImageSlider",
  props: ["attachments"],
  data() {
    return {
      currentIndex: 0
    };
  },
  components: {},
  watch: {
    attachments(newVal, oldVal) {
      if (newVal.length > 0 && this.currentIndex > newVal.length - 1) {
        this.currentIndex = newVal.length - 1;
      }
    }
  },
  computed: {
    slides() {
      let images = [];
      if (this.attachments) {
        this.attachments.map(el => {
          if (el && el?.worksessionSurveyResponsePhoto) {
            images.push(
              ApiService.getImageOrFileGlobalUri(
                el.worksessionSurveyResponsePhoto
              )
            );
          }
        });
      }
      return images;
    }
  },
  methods: {
    nextSlide() {
      if (this.currentIndex < this.slides.length - 1) {
        this.currentIndex++;
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    }
  }
};
</script>

<style scoped>
.image-slider {
  overflow: hidden;
  position: relative;
}

.slides {
  display: flex;
  transition: transform 0.3s ease;
}

.slide {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
}

.image-slider img {
  max-width: 100%;
  max-height: 50vh;
}

button.btn_slider {
  position: absolute;
  top: 50%;
}
button.btn_slider.btn_next {
  right: 2rem;
}
button.btn_slider.btn_prev {
  left: 2rem;
}
</style>
